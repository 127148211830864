import React, { FC, useState, useContext } from 'react';
import { useMedia } from 'react-use';
import { useHistory } from 'react-router-dom';
// @ts-ignore
import styled, { Box, css } from '@xstyled/styled-components';
import { breakpoints } from '@xstyled/system';
import { IDropdownOption, Panel } from '@fluentui/react';
// components
import { Button, Icon, Link, Text, Dropdown } from '../common';
import { Navigation } from './Navigation';
import { LANGUAGE, setLocalForageItem } from '../../services/localStorage';
import { logError } from '../../services/seq';
import { Language } from '../../context/language';

interface IHeaderProps {
  isLoggedIn?: boolean;
}

export const Header: FC<IHeaderProps> = () => {
  const [isOpen, togglePanel] = useState<boolean>(false);
  const isTabletOrDesktop = useMedia('(min-width: 768px)');
  const history = useHistory();
  const iconStyles = { marginRight: '8px', color: '#e01b26', fontSize: '1.15rem' };
  const dropdownTextStyles = { fontSize: '1.15rem', color: '#e01b26' };
  const { language, setLanguage, isFrench } = useContext(Language);

  const langugeOptions = [
    {
      key: 'en-us',
      text: 'English',
      data: { icon: 'Globe' }
    },
    {
      key: 'fr-ca',
      text: 'Français',
      data: { icon: 'Globe' }
    }
  ];

  const onRenderTitle = (options: IDropdownOption[]): JSX.Element => {
    const option = options[0];

    return (
      <div>
        {option.data && option.data.icon && <Icon style={iconStyles} iconName={option.data.icon} aria-hidden="true" title={option.data.icon} />}
        <span style={dropdownTextStyles}>{option.text}</span>
      </div>
    );
  };

  const onRenderOption = (option: IDropdownOption): JSX.Element => {
    return (
      <div>
        {option.data && option.data.icon && <Box style={iconStyles} aria-hidden="true" title={option.data.icon} />}
        <span style={dropdownTextStyles}>{option.text}</span>
      </div>
    );
  };

  return (
    <>
      <Wrapper>
        <Box display="flex" alignItems="center" maxWidth="x56" margin="0 auto">
          {!isTabletOrDesktop && history.location.pathname !== '/' && history.location.pathname !== '/' && (
            <StyledButton
              display="flex"
              alignItems="center"
              forwardedAs="a"
              backgroundColor="white"
              marginRight="m"
              padding="0"
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              <Icon iconName="ChevronLeft" color="primary" />
              <Text color="primary" fontSize="s">
                {isFrench ? 'Retour' : 'Back'}
              </Text>
            </StyledButton>
          )}
          <Title>
            <StyledLink to="/">
              <Box forwardedAs="span" color="primary" fontFamily="secondary">
                Coke<sup>®</sup>
              </Box>{' '}
              <Box forwardedAs="span" color="gray">
                Service
              </Box>
            </StyledLink>
          </Title>
          {!isTabletOrDesktop && (
            <StyledButton onClick={() => togglePanel(true)}>
              <MenuIcon iconName="GlobalNavButton" />
            </StyledButton>
          )}
          {isTabletOrDesktop && language && (
            <Box display="flex" alignItems="center" justifyContent="center" flexWrap="wrap">
              <StyledDropdown
                onRenderTitle={onRenderTitle}
                defaultSelectedKey={language}
                onRenderOption={onRenderOption}
                onChange={async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
                  try {
                    await setLocalForageItem(LANGUAGE, option?.key);
                    if (setLanguage) setLanguage(option?.key as string);
                  } catch (error) {
                    logError(error, `Error setting ${LANGUAGE} in local forage`);
                  }
                }}
                options={langugeOptions}
              />
            </Box>
          )}
        </Box>
      </Wrapper>
      {!isTabletOrDesktop && language && (
        <Box display="flex" margin={{ xs: 's', md: 'm' }} alignItems="center" justifyContent="left" flexWrap="wrap">
          <StyledDropdown
            onRenderTitle={onRenderTitle}
            defaultSelectedKey={language}
            marginTop={{ xs: 0 }}
            onRenderOption={onRenderOption}
            onChange={async (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption | undefined) => {
              try {
                await setLocalForageItem(LANGUAGE, option?.key);
                if (setLanguage) setLanguage(option?.key as string);
              } catch (error) {
                logError(error, `Error setting ${LANGUAGE} in local forage`);
              }
            }}
            options={langugeOptions}
          />
        </Box>
      )}
      {!isTabletOrDesktop && (
        <Panel isLightDismiss isOpen={isOpen} onDismiss={() => togglePanel(false)} closeButtonAriaLabel="Close" styles={{ main: { width: '300px' } }}>
          <Navigation showNavigation />
        </Panel>
      )}
    </>
  );
};

const StyledDropdown = styled(Dropdown)`
  div {
    display: flex;
    align-items: center;
  }
  div > span {
    background-color: transparent;
    color: #e01b26;
    border: 0;
  }
`;

const Wrapper = styled.header(
  breakpoints({
    xs: css`
      background-color: white;
      padding: m m;
    `,
    lg: css`
      padding: x1 0;
    `
  })
);

const StyledLink = styled(Link)`
  :hover {
    text-decoration: none;
  }
`;

const Title = styled.h1(
  breakpoints({
    xs: css`
      margin: 0;
      font-size: xxxl;
      flex: 1;
      font-weight: basic;
    `,
    md: css`
      font-size: xxxxl;
    `
  })
);

const MenuIcon = styled(Icon)`
  border-radius: 50%;
  background-color: primary;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.9rem;
  height: 2.9rem;
  color: white;
  font-weight: bold;
  font-size: xxl;
`;

const StyledButton = styled(Button)`
  padding: 0;
  :hover {
    background-color: transparent;
  }
`;
