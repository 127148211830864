import to from 'await-to-js';
import { axios } from '../helpers';
import { logError } from '../services';
import { ILoginV2Post, ILoginV2PostResponse, ILoginV2Put, ILoginV2PutResponse } from '../models';

/**
 * Create a lookup audit in SQL
 * @param data ILoginV2Post
 * @returns Promise<{ err: Error; res: ILoginV2PostResponse | null }>
 */
export const postLogin = async (data: ILoginV2Post): Promise<{ err: Error | null; res: ILoginV2PostResponse | null }> => {
  const [err, res] = await to(axios.post('logins', data, {}, 'v2'));

  if (err) {
    logError(err, 'fetch/login.ts postLogin');
  }
  return { err, res: res && res.data ? res.data : null };
};

/**
 * Update a lookup audit after creating a request with the requestId
 * @param data ILoginV2Put
 * @returns Promise<{ err: Error; res: ILoginV2PutResponse | null }>
 */
export const putLogin = async (id: number, data: ILoginV2Put): Promise<{ err: Error | null; res: ILoginV2PutResponse | null }> => {
  const [err, res] = await to(axios.put(`logins/${id}`, data, {}, 'v2'));

  if (err) {
    logError(err, 'fetch/login.ts postLogin');
  }
  return { err, res: res && res.data ? res.data : null };
};
