import * as localForage from 'localforage';
import to from 'await-to-js';
import { logError } from './seq';

export const setCameraPermissions = async (val: string) => {
  const [err] = await to(localForage.setItem('CAM_PERMISSION', val));

  if (err) {
    logError(err, 'Error setting CAM_PERMISSION in local forage');
  }
};

export const hasGrantedCameraPermissions = async (): Promise<boolean> => {
  const [err, data] = await to(localForage.getItem('CAM_PERMISSION'));

  if (err) {
    logError(err, 'Error getting CAM_PERMISSION from local forage');
  }
  return data === 'true' || false;
};

const LOGIN_ID_KEY = 'LOGIN_ID';
export const TIMER_KEY = 'TIMER_KEY';
export const LANGUAGE = 'LANGUAGE';

export const setLoginId = async (id: number) => {
  const [err] = await to(localForage.setItem(LOGIN_ID_KEY, id));

  if (err) {
    logError(err, 'Error setting LOGIN_ID in local forage');
  }
};

export const getLoginId = async () => {
  const [err, data] = await to(localForage.getItem(LOGIN_ID_KEY));

  if (err) {
    logError(err, 'Error getting LOGIN_ID from local forage');
  }

  return data || undefined;
};

export const removeLoginId = async () => {
  const [err] = await to(localForage.removeItem(LOGIN_ID_KEY));

  if (err) {
    logError(err, 'Error removing LOGIN_ID from local forage');
  }
};

export const setLocalForageItem = async (key: string, value: any) => {
  const [err] = await to(localForage.setItem(key, value));

  if (err) {
    logError(err, `Error setting ${key} in local forage`);
  }
};

export const getLocalForageItem = async (key: string) => {
  const [err, data] = await to(localForage.getItem(key));

  if (err) {
    logError(err, `Error setting ${key} in local forage`);
  }

  return data || undefined;
};

export const removeLocalForageItem = async (key: string) => {
  const [err] = await to(localForage.removeItem(key));

  if (err) {
    logError(err, `Error removing ${key} from local forage`);
  }
};
