import to from 'await-to-js';
import { axios } from '../helpers';
import { logError } from '../services';
import { IContactRoleInner, ISubmitServiceRequest, ISubmitServiceRequestRes, ILookupServiceRequest } from '../models';

export const getContactRoles = async (): Promise<{ err: Error | null; res: IContactRoleInner[] }> => {
  const [err, res] = await to(axios.get(`contactRoles`, {}, 'v1'));

  if (err) {
    logError(err, 'fetch/request.ts getContactRoles');
  }
  return { err, res: res && res.data && res.data.contactRoles.length > 0 ? res.data.contactRoles : [] };
};

export const createServiceRequest = async (data: ISubmitServiceRequest): Promise<{ err: Error | null; res: ISubmitServiceRequestRes | null }> => {
  const [err, res] = await to(axios.post(`equipmentRepairRequests`, data, {}, 'v4'));

  if (err) {
    logError(err, 'fetch/request.ts createServiceRequest');
  }
  return { err, res: res && res.data ? res.data : null };
};

export const fetchServiceRequest = async (ticketNumber: string, accountNumber?: string, phoneNumber?: string): Promise<{ err: Error | null; res: ILookupServiceRequest }> => {
  const [err, res] = await to(
    axios.get(
      `customerEquipmentRepairRequests?ticketNumber=${ticketNumber}${accountNumber ? `&outletNumber=${accountNumber}` : ''}${
        phoneNumber ? `&contactPhoneNumber=${phoneNumber}` : ''
      }`
    )
  );

  if (err) {
    logError(err, 'fetch/request.ts fetchServiceRequest');
  }
  return { err, res: res ? res?.data?.equipmentRepairRequest : null };
};
