import config from '../buildSettings.json';

const createSeqLog = async (level: string, details: any, message: string) => {
  const options = {
    Application: 'CCBSS - Self Service',
    Environment: config.REACT_APP_SEQ_ENVIRONMENT,
    ...(details ? details.toJSON() : details) //TODO:  Determine if this is going to break something in the future.  We are double logging errors in certain places
  };

  try {
    const event = { '@t': new Date(), '@m': message, '@l': level, ...options };
    await fetch(`${config.REACT_APP_SEQ_SERVER_URL}/api/events/raw?clef&apiKey=${config.REACT_APP_SEQ_API_KEY}`, {
      body: JSON.stringify(event),
      method: 'POST',
      // sending the envents as no-cors otherwise we get CORS errors, still works fine https://logs.mwks.io/#/events?filter=Application%20%3D%20'CCBSS%20-%20Self%20Service'
      mode: 'no-cors',
      headers: {
        'Content-Type': 'application/json'
      }
    });
  } catch (error) {
    //console.log(error);
  }
};

export const logInfo = (details: any, message: string) => {
  createSeqLog('Information', details, message);
};

export const logWarn = (details: any, message: string) => {
  createSeqLog('Warning', details, message);
};

export const logError = (details: any, message: string) => {
  createSeqLog('Error', details, message);
};
