import to from 'await-to-js';
import { axios, DEFAULT_LIMIT } from '../helpers';
import { logError } from '../services';
import { getEquipmentByAssetId } from '../fetch';
import { IOutletBase, IOutletsBase, IEquipmentInner, IOutletInner } from '../models';

export const fetchAccount = async (accountNumber: string): Promise<{ err: Error | null; res: IOutletBase }> => {
  const [err, res] = await to(axios.get(`outlets/${accountNumber}`, {}, 'v3'));

  if (err) {
    logError(err, 'fetch/account.ts fetchAccount');
  }
  return { err, res: res ? res.data : null };
};

export const fetchAccounts = async (param: string): Promise<{ err: Error | null; res: IOutletsBase }> => {
  const [err, res] = await to(axios.get(`outlets?${param}&limit=${DEFAULT_LIMIT}&hasEquipment=false`, {}, 'v3'));

  if (err) {
    logError(err, 'fetch/account.ts fetchAccounts');
  }
  return { err, res: res ? res.data : null };
};

export const getAccountByAssetId = async (
  barcodeValue: string,
  barcodeScan: boolean = false
): Promise<{
  account: IOutletInner | Object;
  err: Error | null;
  equipment: IEquipmentInner | null;
}> => {
  const [err, data] = await to(getEquipmentByAssetId(barcodeValue, barcodeScan));
  if (err) {
    logError(err, 'fetch/account.ts getAccountByAssetId');
  }
  if (data && data.res && !data.res.noResults) {
    // @ts-ignore
    const [, accountRes] = await to(fetchAccount(data.res.outletNumber));
    if (accountRes && accountRes.err) {
      logError(err, 'fetch/account.ts getAccountByAssetId');
    }
    return {
      account: accountRes ? accountRes.res.outlet : {},
      err: accountRes ? accountRes.err : null,
      equipment: data.res as IEquipmentInner
    };
  }
  return { err, account: {}, equipment: null };
};
