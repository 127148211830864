import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

let reactPlugin: any = null;
let appInsights: any = null;

/**
 * Create the App Insights Telemetry Service
 * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
 */
export const createAppInsights = () => {
  const initialize = (instrumentationKey: string, browserHistory: object) => {
    if (!browserHistory) {
      throw new Error('Could not initialize Application Insights');
    }
    if (!instrumentationKey) {
      throw new Error('Instrumentation key not provided in ./src/components/providers/InsightsProvider.jsx');
    }

    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
      config: {
        instrumentationKey,
        maxBatchInterval: 0,
        disableFetchTracking: false,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: {
            history: browserHistory
          }
        }
      }
    });

    appInsights.loadAppInsights();
  };

  return { reactPlugin, appInsights, initialize };
};

export const createAI = createAppInsights();
export const getAppInsights = () => appInsights;
export const logPageView = (name?: string, url?: string, properties?: any, measurements?: any, duration?: number) => {
  if (getAppInsights()) {
    getAppInsights().trackPageView(name, url, properties, measurements, duration);
  }
};
export const logEvent = (name: string, properties?: any, measurements?: any) => {
  if (getAppInsights()) {
    getAppInsights().trackEvent(name, properties, measurements);
  }
};
