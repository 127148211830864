import to from 'await-to-js';
import { axios } from '../helpers';
import { logError } from '../services';
import { IZipCodePhoneInfo } from '../models';

export const fetchPhoneNumberByZipCode = async (zip: string): Promise<{ err: Error | null; res: IZipCodePhoneInfo }> => {
  const [err, res] = await to(axios.get(`zipToPhoneLookups/${zip}`, {}));

  if (err) {
    logError(err, 'fetch/zipCodeLookup.ts fetchPhoneNumberByZipCode');
  }
  return { err, res: res ? res.data.zipToPhoneLookup : null };
};
