import to from 'await-to-js';
import { axios } from '../helpers';
import { logError } from '../services';
import { IPrivacyPolicyInner } from '../models/privacy-policy';
import { Apps } from '../models/app';

export const fetchPrivacyPolicy = async (): Promise<{ err: Error | null; res: IPrivacyPolicyInner }> => {
  const [err, res] = await to(axios.get(`privacy-policies?appId=${Apps.COKE_SERVICES_APP}`, {}, 'v1'));

  if (err) {
    logError(err, 'fetch/privacyPolicies.ts fetchPrivacyPolicy');
  }
  return { err, res: res ? res?.data?.privacyPolicies[0] : null };
};
